const FolderActions = {
    CREATE_FOLDER: "create_folder",
    RENAME_ITEM: "rename_item",
    DELETE_FOLDER: "delete_folder",
    MOVE_TO_FOLDER: "move_to_folder",
    REMOVE_FROM_FOLDER: "remove_from_folder",
    DELETE_ITEM: "delete_item",
    CREATE_FOLDER_MOVE_ITEM: "create_folder_move_item",
};

export default { FolderActions };
