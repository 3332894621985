export class Toast {
    static error(message) {
        window
            .Toastify({
                text: message,
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                style: {
                    background: "white",
                    border: "solid 1px red",
                    color: "black",
                    borderRadius: "5px",
                    boxShadow: "1px 1px 5px rgba(red, 0.25);",
                    fontWeight: "600",
                    textAlign: "left",
                },
            })
            .showToast();
    }

    static success(message) {
        window
            .Toastify({
                text: message,
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: "top",
                position: "right",
                stopOnFocus: true,
                style: {
                    background: "white",
                    border: "solid 1px green",
                    color: "black",
                    borderRadius: "5px",
                    boxShadow: "1px 1px 5px rgba(green, 0.25);",
                    fontWeight: "600",
                    textAlign: "left",
                },
            })
            .showToast();
    }
}
