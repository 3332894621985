export class UserFolder {
    static messages = {
        addToFolder: "Aggiunto alla raccolta con successo.",
        addToFolderError: "Errore riprova più tardi.",
    };

    static async getFolder(folderId, userFoldersHtmlNode) {
        const data = await window.folderApi.getFolder(folderId);
        if (!data) {
            window.Toast.error(UserFolder.messages.addToFolderError);
            return;
        }

        const folderSelectedEvent = new CustomEvent("folderselected", {
            detail: {
                folderId: data.data?.id,
                lists: data.data?.favorites_lists,
            },
        });
        userFoldersHtmlNode.dispatchEvent(folderSelectedEvent);
    }

    static async addToFolder(
        folderId,
        listId,
        userFoldersHtmlNode,
        successToast = true,
        inFolder = false
    ) {
        const data = await window.folderApi.addToFolder(folderId, listId);
        if (!data) {
            window.Toast.error(UserFolder.messages.addToFolderError);
            return;
        }

        if (successToast) {
            window.Toast.success(UserFolder.messages.addToFolder);
        }

        if (inFolder) {
            const movedtofolder = new CustomEvent("movedtofolder", {
                detail: {
                    listId: listId,
                },
            });
            userFoldersHtmlNode.dispatchEvent(movedtofolder);
        }
    }

    static async removeFromFolder(folderId, listId) {
        const data = await window.folderApi.removeFromFolder(folderId, listId);
        if (!data) {
            window.Toast.error(UserFolder.messages.addToFolderError);
            return;
        }

        const listHtmlNode = document.getElementById(`list-${listId}`);
        listHtmlNode.remove();
    }

    static async createFolder(
        name,
        userFoldersHtmlNode
        /*  listId = null,
        listHtmlNodeId = null */
    ) {
        const data = await window.folderApi.createFolder(name);
        if (!data.data) {
            UserFolder.showValidationError(data?.error, userFoldersHtmlNode);
            return;
        }

        const folderCreatedEvent = new CustomEvent("foldercreated", {
            detail: {
                folder: data.data,
            },
        });
        userFoldersHtmlNode.dispatchEvent(folderCreatedEvent);
    }

    static async deleteFolder(folderId, userFoldersHtmlNode) {
        const data = await window.folderApi.deleteFolder(folderId);
        if (!data) {
            window.Toast.error(UserFolder.messages.addToFolderError);
            return;
        }

        const folderDeletedEvent = new CustomEvent("folderdeleted", {
            detail: {
                folderId: folderId,
            },
        });
        userFoldersHtmlNode.dispatchEvent(folderDeletedEvent);
    }

    static async renameItem(folderId, name, userFoldersHtmlNode) {
        const data = await window.favoritesListApi.renameFavoritesList(
            folderId,
            name
        );
        if (!data) {
            window.Toast.error(UserFolder.messages.addToFolderError);
            return;
        }

        const folderRenamedEvent = new CustomEvent("itemrenamed", {
            detail: {
                list: data.data.list,
            },
        });
        userFoldersHtmlNode.dispatchEvent(folderRenamedEvent);
    }

    static async deleteItem(listId, userFoldersHtmlNode) {
        const data = await window.favoritesListApi.deleteFavoritesList(listId);
        if (!data) {
            window.Toast.error(UserFolder.messages.addToFolderError);
            return;
        }

        const listDeletedEvent = new CustomEvent("itemdeleted", {
            detail: {
                listId: listId,
            },
        });
        userFoldersHtmlNode.dispatchEvent(listDeletedEvent);
    }

    static showValidationError(message, userFoldersHtmlNode) {
        const showValidationErrorEvent = new CustomEvent("validationerror", {
            detail: { message: message },
        });
        userFoldersHtmlNode.dispatchEvent(showValidationErrorEvent);
    }

    static initSortable(listHtmlNode) {
        return window.Sortable.create(listHtmlNode, {
            animation: 150,
            handle: ".handle",
            ghostClass: ".ghost",
            store: {
                set: (sortable) => {
                    const sortedItems = sortable.toArray().filter((item) => {
                        return item !== "loader";
                    });
                    const folderId = sortable.el.getAttribute("folder-id");
                    if (!folderId) {
                        return;
                    }

                    window.folderApi.sortFolder(folderId, sortedItems);
                },
            },
        });
    }
}
