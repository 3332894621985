export class FavoritesTutorialModal {
    static show(htmlNode) {
        const modal = new window.bootstrap.Modal(htmlNode);
        if (!modal) {
            console.error("Modal not found");
            return;
        }

        const hideTutorialModal =
            localStorage.getItem("hideTutorialModal") ?? false;
        const tutorialModalShown =
            sessionStorage.getItem("tutorialModalShown") ?? false;
        if (!Number(hideTutorialModal) && !tutorialModalShown) {
            modal.show();
        }
    }

    static saveChoice(choice) {
        localStorage.setItem("hideTutorialModal", Number(choice));
        sessionStorage.setItem("tutorialModalShown", 1);
    }
}
