// Per import dipendenze esterne usare bootstrap.js
import "./bootstrap";

/******
IMAGES 
*******/
import.meta.glob(["../images/**"]);

import ss1image from "../images/i-grado-illustrazione.png";
import ss2image from "../images/ii-grado-illustrazione.png";
import primariaimage from "../images/primaria-illustrazione.png";
window.illustrations = {
    "sec-i-grado": ss1image,
    "sec-ii-grado": ss2image,
    primaria: primariaimage,
};

import utils from "./frontend/utils";
window.Utils = utils;

/******* 
FRONTEND
********/
import favoritesListApi from "./frontend/Api/favoritesListApi";
window.favoritesListApi = favoritesListApi;

import folderApi from "./frontend/Api/folderApi";
window.folderApi = folderApi;

import filters from "./frontend/filters";
window.Filters = filters;

import Enums from "./frontend/enums";
window.Enums = Enums;

import { HeaderObeserver } from "./frontend/HeaderObeserver";
window.HeaderObeserver = HeaderObeserver;

/********** 
 COMPONENTS 
**********/
import { Toast } from "./components/Toast";
window.Toast = Toast;

import { Slider } from "./components/Slider";
window.Slider = Slider;

import { Nav } from "./components/Nav";
window.Nav = Nav;

import { Events } from "./components/Events";
window.Events = Events;

import { Modal } from "./components/Modal";
window.Modal = Modal;

import { Dropdown } from "./components/Dropdown";
window.Dropdown = Dropdown;

import DropdownGroup from "./components/DropdownGroup";
window.DropdownGroup = DropdownGroup;

import { GridBox } from "./components/GridBox";
window.GridBox = GridBox;

import { FavoritesModal } from "./components/FavoritesModal";
window.FavoritesModal = FavoritesModal;

import { FavoritesList } from "./components/FavoritesList";
window.FavoritesList = FavoritesList;

import { WelcomeModal } from "./components/WelcomeModal";
window.WelcomeModal = WelcomeModal;

import { UserFolder } from "./components/UserFolder";
window.UserFolder = UserFolder;

import { FavoritesTutorialModal } from "./components/FavoritesTutorialModal";
window.FavoritesTutorialModal = FavoritesTutorialModal;

Alpine.data("dropdown", DropdownGroup);

// AlpineJS | DEVE ESSERE SEMPRE L'ULTIMA RIGA
Alpine.start();
