async function filterResources(
    gridBoxId,
    selectedItems,
    unselectedItems,
    category_id = null,
    recursive = false,
    dropdownGroup = false
) {
    const params = {
        ssr: 1,
        filter_ids: [],
    };

    if (category_id) {
        params.category_id = category_id;
    }

    if (recursive) {
        params.recursive = 1;
    }

    if (dropdownGroup) {
        params.type_ids = [];
        for (const item of selectedItems.filters) {
            params.filter_ids.push(item.getAttribute("_id"));
        }

        for (const item of selectedItems.types) {
            params.type_ids.push(item.getAttribute("_id"));
        }
    } else {
        for (const item of selectedItems) {
            params.filter_ids.push(item.getAttribute("_id"));
        }
    }

    let queryString = window.Utils.buildQueryParamsString(params);

    const gridBox = window.Alpine.store(gridBoxId);
    gridBox.wipeGridBox();
    gridBox.showLoader();

    let resources;
    try {
        const url = new URL(`api/v1/resources${queryString}`, location.origin);
        const response = await window.axios.get(url);
        resources = response.data.data;
    } catch (error) {
        console.error(`filterResources: ${error}`);
    }

    gridBox.updateGridBox(resources);
    gridBox.hideLoader();
}

async function filterLectures(gridBoxId, selectedItems, unselectedItems) {
    const gridBox = window.Alpine.store(gridBoxId);
    gridBox.wipeGridBox();
    gridBox.showLoader();

    const params = {
        ordine: [],
        filtro: [],
    };

    const categoriesDropdown = document.querySelector(
        "[_id='categories-dropdown']"
    );
    const filtersDropdown = document.querySelector("[_id='filters-dropdown']");

    if (categoriesDropdown) {
        const selectedItems =
            categoriesDropdown.querySelectorAll("input:checked");
        for (const item of selectedItems) {
            params.ordine.push(item.getAttribute("slug"));
        }
    }

    if (filtersDropdown) {
        const selectedItems = filtersDropdown.querySelectorAll("input:checked");
        for (const item of selectedItems) {
            params.filtro.push(item.getAttribute("slug"));
        }
    }

    let queryString = window.Utils.buildQueryParamsString(params);

    location.href = `/lezioni${queryString}`;
}

// Used inside category-with-resources.blade.php
async function filterLecturesApi(
    gridBoxId,
    selectedItems,
    unselectedItems,
    categoryId
) {
    const params = {
        categories_ids: [categoryId],
        filters_ids: [],
        ssr: 1,
    };

    for (const item of selectedItems) {
        params.filters_ids.push(item.getAttribute("_id"));
    }

    let queryString = window.Utils.buildQueryParamsString(params);

    const gridBox = window.Alpine.store(gridBoxId);
    gridBox.wipeGridBox();
    gridBox.showLoader();

    let lectures;

    try {
        const url = new URL(`api/v1/lectures${queryString}`, location.origin);
        const response = await window.axios.get(url);
        lectures = response.data.data;
    } catch (error) {
        console.error(`filterLectures: ${error}`);
    }

    gridBox.updateGridBox(lectures);
    gridBox.hideLoader();
}

async function resourcesFiltersGroup(
    queryParams,
    gridBoxId,
    category_id,
    defaultFilter = undefined
) {
    const gridBox = window.Alpine.store(gridBoxId);
    gridBox.wipeGridBox();
    gridBox.showLoader();

    const params = {
        ssr: 1,
        recursive: 1,
    };

    if (category_id) {
        params.category_id = category_id;
    }

    if (defaultFilter) {
        params.filter_ids = [defaultFilter];
    }

    if (queryParams.filters) {
        params.filter_ids = queryParams.filters;
    }

    if (queryParams.types) {
        params.type_ids = queryParams.types;
    }

    if (queryParams.volumes) {
        params.volume_ids = queryParams.volumes;
    }

    let queryString = window.Utils.buildQueryParamsString(params);

    let resources;
    try {
        const url = new URL(`api/v1/resources${queryString}`, location.origin);
        const response = await window.axios.get(url);
        resources = await response.data.data;
    } catch (error) {
        console.error(`filterResources: ${error}`);
    }

    gridBox.updateGridBox(resources);
    gridBox.hideLoader();
}

export default {
    filterResources,
    filterLectures,
    filterLecturesApi,
    resourcesFiltersGroup,
};
