async function deleteFavoritesList(id) {
    let response;
    try {
        response = await axios.delete("/api/v1/favorites/" + id);
    } catch (e) {
        console.error(e);
        return false;
    }

    return await response.data;
}

async function addFavoritesList(name) {
    let response;
    const resData = { data: null, error: null };
    try {
        response = await axios.post("/api/v1/favorites", {
            name: name,
            description: "",
        });
    } catch (e) {
        resData.error = e.response?.data?.error ?? e;
        return resData;
    }

    resData.data = await response.data;
    return resData;
}

async function deleteResource(favoriteId, resourceId, resourceType) {
    let response;
    try {
        response = await axios.delete(
            `/api/v1/favorites/${favoriteId}/addables/${resourceId}?type=${resourceType}`
        );
    } catch (e) {
        console.error(e);
        return false;
    }

    return await response.data;
}

async function addResource(favoriteId, resourceId, type) {
    const body = {
        resource_id: resourceId,
        type: type,
    };

    let response;
    const resData = { data: null, error: null };
    try {
        response = await axios.post(
            `/api/v1/favorites/${favoriteId}/addables`,
            body
        );
    } catch (e) {
        resData.error = e.response?.data?.error ?? e;
        return resData;
    }

    resData.data = await response.data;
    return resData;
}

async function updateResourcesOrder(favoriteId, resources) {
    const body = {
        resources: resources,
    };

    let response;
    try {
        response = await axios.put(
            `/api/v1/favorites/${favoriteId}/addables`,
            body
        );
    } catch (e) {
        console.error(e);
        return false;
    }

    return await response.data;
}

async function renameFavoritesList(id, name) {
    let response;
    const resData = { data: null, error: null };
    try {
        response = await axios.patch(`/api/v1/favorites/${id}`, {
            name: name,
        });
    } catch (e) {
        resData.error = e.response?.data?.error ?? e;
        return resData;
    }

    resData.data = await response.data.data;
    return resData;
}
export default {
    deleteFavoritesList,
    addFavoritesList,
    deleteResource,
    addResource,
    updateResourcesOrder,
    renameFavoritesList,
};
