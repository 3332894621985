export class FavoritesList {
    static animationDuration = 0.4;

    static async removeResource(
        resourceHtmlNode,
        listId,
        resourceId,
        resourceType
    ) {
        // Delete from list
        const result = await window.favoritesListApi.deleteResource(
            listId,
            resourceId,
            resourceType
        );
        if (!result || result.error) {
            console.error("Erorr removing resource");
            window.Toast.error(
                "È stato impossibile rimuovere l'oggetto digitale. Riprova piu tardi."
            );
            return;
        }

        // Remove element from dom
        const parentNode = resourceHtmlNode.parentNode;
        resourceHtmlNode.remove();

        // Recompute buttons state
        FavoritesList.updateItemsCount(parentNode);

        // If list is empty, show message
        if (!parentNode.children.length) {
            const message = document.createElement("span");
            message.textContent =
                "Nessun oggetto digitale aggiunto al percorso.";
            parentNode.appendChild(message);
            return;
        }
    }

    static async saveResourcesListOrder(container, listId) {
        const resources = container.querySelectorAll("[_id][_type]");
        const resourcesData = [];
        for (const resource of resources) {
            const id = resource.getAttribute("_id");
            const type = resource.getAttribute("_type");
            resourcesData.push({
                id: id,
                type: type,
            });
        }

        const result = await window.favoritesListApi.updateResourcesOrder(
            listId,
            resourcesData
        );
        if (!result || result.error) {
            console.error("Erorr saving resources list order");
            window.Toast.error(
                "È stato impossibile salvare l'ordine degli oggetti digitali. Riprova più tardi."
            );
            return;
        }
    }

    static updateItemsCount(listHtmlNode) {
        const count = listHtmlNode.children.length;
        const event = new CustomEvent("itemremove", {
            detail: { itemsCount: count },
        });
        listHtmlNode.dispatchEvent(event);
    }

    static initSortable(listHtmlNode, listId) {
        new window.Sortable.create(listHtmlNode, {
            animation: FavoritesList.animationDuration,
            handle: ".handle",
            onEnd: async (evt) => {
                FavoritesList.saveResourcesListOrder(listHtmlNode, listId);
            },
        });
    }
}
