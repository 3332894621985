export default (tree, flatMap, gridBoxId, callback, callbackParams) => ({
    tree: tree,
    flatMap: flatMap,
    gridBoxId: gridBoxId,
    callback: callback,
    callbackParams: callbackParams,
    depth: 0,

    init() {
        this.createDropdowns(this.tree);
    },

    createDropdowns(dropdowns) {
        const currentDepth = this.depth;
        this.depth++;

        for (const dropdown of dropdowns) {
            const dropdownHtmlNode = document.querySelector(
                `[_id="${dropdown.id}"]`
            );

            if (!dropdownHtmlNode) {
                console.warn("DropdownGroup: dropdownHtmlNode not found.");
                continue;
            }

            if (currentDepth === 0) {
                dropdownHtmlNode.classList.remove("d-none");
            }

            for (const item of dropdown.items) {
                const itemHtmlNode = dropdownHtmlNode.querySelector(
                    `[_id="${item.id}"]`
                );

                if (!itemHtmlNode) {
                    console.warn("DropdownGroup: itemHtmlNode not found.");
                    continue;
                }

                itemHtmlNode.addEventListener("click", () => {
                    ("filter triggered");
                    for (const it of dropdown.items) {
                        if (it === item || !it.dropdowns) {
                            continue;
                        }

                        for (const itemDropdown of it.dropdowns) {
                            this.hideDropdown(itemDropdown);
                        }
                    }

                    if (item.dropdowns) {
                        for (const itemDropdown of item.dropdowns) {
                            this.showDropdown(itemDropdown);
                        }
                    }

                    const queryParams = {};
                    for (const drop of flatMap) {
                        const htmlNode = document.querySelector(
                            `[_id="${drop.id}"]`
                        );
                        if (!htmlNode) {
                            console.warn(
                                "DropdownGroup: dropdownHtmlNode not found."
                            );
                            continue;
                        }

                        if (htmlNode.classList.contains("d-none")) {
                            continue;
                        }

                        const inputs = htmlNode.querySelectorAll(
                            `[type="checkbox"]:checked, [type="radio"]:checked`
                        );

                        const ids = [];
                        for (const input of inputs) {
                            ids.push(input.value);
                        }

                        queryParams[drop.query] = ids;
                    }

                    this.callback(
                        queryParams,
                        this.gridBoxId,
                        ...this.callbackParams
                    );
                });

                if (item.dropdowns) {
                    this.createDropdowns(item.dropdowns);
                }
            }
        }
    },

    hideDropdown(dropdown) {
        const htmlNode = document.querySelector(`[_id="${dropdown.id}"]`);
        if (!htmlNode) {
            console.warn("DropdownGroup: dropdownHtmlNode not found.");
            return;
        }

        if (!htmlNode.classList.contains("d-none")) {
            htmlNode.classList.add("d-none");
        }

        const items = htmlNode.querySelectorAll(
            `[type="checkbox"], [type="radio"]`
        );
        for (const item of items) {
            item.checked = false;
        }
    },

    showDropdown(dropdown) {
        const htmlNode = document.querySelector(`[_id="${dropdown.id}"]`);
        if (!htmlNode) {
            console.warn("DropdownGroup: dropdownHtmlNode not found.");
            return;
        }

        if (htmlNode.classList.contains("d-none")) {
            htmlNode.classList.remove("d-none");
        }
    },
});
