function buildQueryParamsString(params) {
    let string = "?";
    for (const key in params) {
        if (params[key] instanceof Array) {
            for (const value of params[key]) {
                string += `${key}[]=${value}&`;
            }
        } else {
            string += `${key}=${params[key]}&`;
        }
    }

    return string;
}

function createNodeFromString(string) {
    const template = document.createElement("template");
    template.innerHTML = string.trim();
    return template.content.firstChild;
}

function getSlug(str) {
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
}

function getImageLink(path) {
    if (path.includes("http")) {
        return path;
    } else {
        return `/storage/${path}`;
    }
}

function findParentTagRecursive(target, tagName) {
    if (target.tagName === tagName) {
        return target;
    }

    if (target.parentElement) {
        return findParentTagRecursive(target.parentElement, tagName);
    }

    return null;
}

export default {
    getImageLink,
    buildQueryParamsString,
    createNodeFromString,
    getSlug,
    findParentTagRecursive,
};
