import { Nav } from "./Nav";

export class Modal {
    static populate(id, selected = null) {
        const modalNode = document.getElementById(id);
        if (!modalNode) {
            console.error("Modal.populateModal: modal not found");
            return;
        }

        modalNode.addEventListener("show.bs.modal", async (event) => {
            const loader = modalNode.querySelector("div[class='loader']");
            if (!loader) {
                console.error("Modal.populateModal: loader not found");
                return;
            }

            loader.style.display = "block";

            const sourceBtn = event.relatedTarget;
            const catId = sourceBtn.getAttribute("data-cat-id");
            const container = modalNode.querySelector("ul[list]");

            if (!container) {
                console.error("Modal.populateModal: container not found");
                return;
            }

            container.innerHTML = "";
            container.style.display = "none";

            const title = document.createElement("span");
            title.innerText = sourceBtn.getAttribute("data-cat-name");
            title.classList.add("modal-title");
            container.appendChild(title);

            const response = await axios.get("/api/v1/categories/" + catId);
            const categories = response.data.data;

            for (let category of categories) {
                const newItem = document.createElement("li");
                newItem.setAttribute("slug", category.slug);
                container.appendChild(newItem);
                Nav.recursiveComponent(category, newItem, selected);
            }

            loader.style.display = "none";
            container.style.display = "block";
        });
    }
}
