export class Slider {
    constructor(id, config, next, prev) {
        const defaultConfig = {
            slidesPerView: "auto",
            watchSlidesProgress: true,
        };

        if (next && prev) {
            defaultConfig.navigation = {
                nextEl: `[next='${id}']`,
                prevEl: `[prev='${id}']`,
            };
        }

        config = config.length > 0 ? JSON.parse(config) : defaultConfig;
        config = { ...defaultConfig, ...config };

        new window.Swiper(`[_id='${id}']`, config);
    }
}
