export class HeaderObeserver {
    counter = -1;
    constructor(nodeHtml) {
        let oldCounter = this.counter;
        const observer = new IntersectionObserver(
            ([e]) => {
                this.counter += 1;

                if (this.counter <= 0) {
                    return;
                }

                if (this.counter !== oldCounter) {
                    if (nodeHtml.classList.contains("scrolled")) {
                        nodeHtml.classList.remove("scrolled");
                    } else {
                        nodeHtml.classList.add("scrolled");
                    }
                }

                oldCounter = this.counter;
            },
            { threshold: 1 }
        );

        observer.observe(nodeHtml);
    }
}
