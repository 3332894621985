export class WelcomeModal {
    static show(htmlNode) {
        // Disabled
        return;
        const modal = new window.bootstrap.Modal(htmlNode);
        if (!modal) {
            console.error("Modal not found");
            return;
        }

        const hideWelcomeModal =
            localStorage.getItem("hideWelcomeModal") ?? false;
        const welcomeModalShown =
            sessionStorage.getItem("welcomeModalShown") ?? false;
        if (!hideWelcomeModal && !welcomeModalShown) {
            modal.show();
        }
    }

    static saveChoice(choice) {
        if (choice) {
            localStorage.setItem("hideWelcomeModal", choice);
        }
        sessionStorage.setItem("welcomeModalShown", true);
    }
}
