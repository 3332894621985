export class FavoritesModal {
    static ISTANCE = null;
    choicesConfig = {
        noResultsText: "Nessun percorso trovato",
        noChoicesText: "Nessun percorso trovato",
        loadingText: "...",
        shouldSort: false,
    };
    defaultErrorMessage =
        "È stato impossibile aggiungere l'oggetto al percorso. Riprova più tardi.";
    createNewlistChoicheText = "<b>Crea nuovo percorso</b>";

    static createInstance(modalHtmlNode, selectHtmlNode) {
        if (FavoritesModal.ISTANCE == null) {
            FavoritesModal.ISTANCE = new FavoritesModal(
                modalHtmlNode,
                selectHtmlNode
            );
        }
        return FavoritesModal.ISTANCE;
    }

    static getInstance() {
        return FavoritesModal.ISTANCE;
    }

    constructor(modalHtmlNode, selectHtmlNode) {
        this.item;
        this.openBtn;
        this.modalInstance = new window.bootstrap.Modal(modalHtmlNode);
        this.favoritesListsBadges = modalHtmlNode.querySelector(
            ".favorites-badges-list"
        );

        // Add options to Choices.js config and create instance
        const lists = Alpine.store("favorites_lists");
        if (!lists) {
            console.error("Lists not found");
            window.Toast.error(this.defaultErrorMessage);
            return;
        }

        this.choicesConfig.choices = lists.map((list) => {
            return {
                value: list.id,
                label: list.name,
                selected: false,
                disabled: false,
            };
        });

        this.choicesConfig.choices.push({
            value: "new-list",
            label: this.createNewlistChoicheText,
            selected: false,
            disabled: false,
        });

        this.selectInstance = new window.Choices(
            selectHtmlNode,
            this.choicesConfig
        );

        this.selectInstance.passedElement.element.addEventListener(
            "choice",
            this.onChange.bind(this)
        );
    }

    open(item, btn) {
        this.item = item;
        // Clear selction
        this.selectInstance.removeActiveItems();
        // Update badges
        this.updateItemFavoriteLists();
        // Show modal
        this.modalInstance.show();

        this.openBtn = btn;
    }

    updateItemFavoriteLists() {
        const updateListEvent = new CustomEvent("updatelists", {
            detail: {
                item: this.item,
                favoritesLists: this.item.favorites_lists,
            },
        });
        this.favoritesListsBadges.dispatchEvent(updateListEvent);
    }

    updateButton() {
        const updateListEvent = new CustomEvent("update", {
            detail: {
                item: this.item,
            },
        });

        this.openBtn.dispatchEvent(updateListEvent);
    }

    showValidationError(message) {
        const showValidationErrorEvent = new CustomEvent("validationerror", {
            detail: { message: message },
        });
        this.modalInstance._element.dispatchEvent(showValidationErrorEvent);
    }

    addSelectOption(newOption) {
        this.choicesConfig.choices.splice(-1, 0, newOption);
        this.selectInstance.setChoices(
            this.choicesConfig.choices,
            "value",
            "label",
            true
        );
    }

    async removeItemFromList(listId) {
        if (this.item == null) {
            console.error("Item not found");
            window.Toast.error(this.defaultErrorMessage);
            return;
        }

        const result = await window.favoritesListApi.deleteResource(
            listId,
            this.item.id,
            this.item.resource_type
        );

        if (!result || result.error) {
            console.error("Error removing resource");
            window.Toast.error(this.defaultErrorMessage);
            return;
        }

        this.item.favorites_lists = this.item.favorites_lists.filter(
            (list) => list.id !== listId
        );

        this.updateItemFavoriteLists();
    }

    async createNewList(name) {
        if (this.item == null) {
            console.error("Item not found");
            window.Toast.error(this.defaultErrorMessage);
            return;
        }

        const addListRes = await window.favoritesListApi.addFavoritesList(name);
        if (addListRes.error) {
            console.error("Error creating list");
            this.showValidationError(addListRes.error);
            return;
        }

        const newListData = addListRes.data.data.list;
        const addResourceRes = await window.favoritesListApi.addResource(
            newListData.id,
            this.item.id,
            this.item.resource_type
        );

        if (addResourceRes.error) {
            console.error("Error adding resource");
            window.Toast.error(addResourceRes.error);

            const event = new CustomEvent("newlistclose");
            this.modalInstance._element.dispatchEvent(event);
            this.modalInstance.hide();

            return;
        }

        this.item.favorites_lists.push({
            id: newListData.id,
            name: newListData.name,
        });

        this.updateItemFavoriteLists();
        this.updateButton();
        this.addSelectOption({
            value: newListData.id,
            label: newListData.name,
            selected: true,
            disabled: false,
        });

        const event = new CustomEvent("newlistclose");
        this.modalInstance._element.dispatchEvent(event);
        this.modalInstance.hide();
    }

    async onChange(event) {
        if (this.item == null) {
            window.Toast.error(this.defaultErrorMessage);
            console.error("Item not found");
        }

        this.selectInstance.disable();

        const lists = Alpine.store("favorites_lists");
        if (!lists) {
            window.Toast.error(this.defaultErrorMessage);
            console.error("Lists not found");
            return;
        }

        const selected = event.detail.choice.value;
        if (selected === "new-list") {
            // Show new list view
            const event = new CustomEvent("newlist");
            this.modalInstance._element.dispatchEvent(event);
            this.selectInstance.enable();
            return;
        }

        // Check if item is already in list
        const inList = this.item.favorites_lists.find(
            (list) => list.id === selected
        );
        if (inList) {
            this.selectInstance.enable();
            return;
        }

        const itemNewData = await window.favoritesListApi.addResource(
            selected,
            this.item.id,
            this.item.resource_type
        );

        if (itemNewData.error) {
            console.error("Error adding resource");
            window.Toast.error(itemNewData.error);
            this.selectInstance.enable();
            this.modalInstance.hide();
            return;
        }

        this.item.favorites_lists.push({
            id: itemNewData.data.data.list.id,
            name: itemNewData.data.data.list.name,
        });
        this.updateItemFavoriteLists();
        this.updateButton();

        this.selectInstance.enable();

        this.modalInstance.hide();
    }
}
