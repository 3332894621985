/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";
window.axios = axios;

// Add global headers
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const _token = document.querySelector("meta[name=csrf-token]");
if (_token && _token.content) {
    window.axios.defaults.headers.common["X-CSRF-Token"] = _token.content;
} else {
    console.error("No CSRF token found.");
}

// Swiper | Importato tutto il bundle volendo si puo selezionare cosa importare
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
window.Swiper = Swiper;

// Bootstrap | Importato tutto il bundle volendo si puo selezionare cosa importare
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import Alpine from "alpinejs";
window.Alpine = Alpine;

import AOS from "aos";
import "aos/dist/aos.css";
// Update AOS items manually to avoid eccessive redraws
AOS.init({ disableMutationObserver: true });
window.AOS = AOS;

import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";
window.Choices = Choices;

import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
window.Toastify = Toastify;

// Forse non piu necessario usato per animazione swap elementi in lista
import gsap from "gsap";
window.gsap = gsap;

import Sortable from "sortablejs";
window.Sortable = Sortable;
