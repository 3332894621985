export class Dropdown {
    static onSelectionChanged(gridBoxId, container, callback, callbackParams) {
        const selectedItems = container.querySelectorAll("input:checked");
        const unselectedItems = container.querySelectorAll(
            "input:not(:checked)"
        );

        if (!callback) {
            return;
        }

        callback(gridBoxId, selectedItems, unselectedItems, ...callbackParams);
    }
}
