export class Nav {
    static recursiveComponent(
        subCategory,
        container,
        selected = null,
        color = null
    ) {
        let subcategories = subCategory.subcategories;

        if (subCategory.resourceCount > 0) {
            const link = document.createElement("a");
            link.href = `/category/${subCategory.id}`;
            link.innerHTML = subCategory.name;

            if (selected && selected === subCategory.slug) {
                link.style.textDecoration = "underline";
            }

            container.appendChild(link);
        } else {
            if (import.meta.env.VITE_ENV_NAME === "fisica") {
                const itemsContainer = document.createElement("div");
                itemsContainer.classList.add("d-flex", "gap-1");

                const circle = document.createElement("div");
                circle.classList.add("circle");
                if (color) {
                    circle.style.backgroundColor = color;
                }

                const name = document.createElement("span");
                name.innerText = subCategory.name;

                itemsContainer.appendChild(circle);
                itemsContainer.appendChild(name);
                container.appendChild(itemsContainer);
            } else {
                container.innerHTML = subCategory.name;
            }
        }

        if (subcategories.length > 0) {
            const newList = document.createElement("ul");
            return subcategories.map((item) => {
                const newItem = document.createElement("li");
                newItem.setAttribute("slug", item.slug);

                newList.appendChild(newItem);
                container.appendChild(newList);

                return this.recursiveComponent(item, newItem, selected);
            });
        }

        return subCategory.name;
    }

    static onItemClick(evt) {
        let container = evt.target;
        if (evt.target.tagName !== "LI") {
            container = window.Utils.findParentTagRecursive(evt.target, "LI");
        }

        const list = container.querySelector("ul");
        if (!list) {
            return;
        }

        if (import.meta.env.VITE_ENV_NAME === "fisica") {
            const expanded = document.querySelectorAll(".expanded");
            for (const expaded of expanded) {
                if (expaded === list) {
                    continue;
                }

                expaded.classList.remove("expanded");

                const circle = expaded.parentElement?.querySelector(".circle");
                if (circle) {
                    circle.style.display = "none";
                }
            }
        }

        if (list.classList.contains("expanded")) {
            list.classList.remove("expanded");

            const circle = list.parentElement?.querySelector(".circle");
            if (circle) {
                circle.style.display = "none";
            }
            return;
        }

        list.classList.add("expanded");
        const circle = list.parentElement?.querySelector(".circle");
        if (circle) {
            circle.style.display = "block";
        }
    }

    static initHeaderObserver(nodeHtml) {
        window.addEventListener("scroll", (evt) => {
            if (window.scrollY > 0) {
                nodeHtml.classList.add("scrolled");
            } else {
                nodeHtml.classList.remove("scrolled");
            }
        });
    }

    static setButtonsBackground(htmlNodeBtn, color) {
        const buttonsContainer = document.querySelector(
            ".categories-button-container"
        );

        const buttons = buttonsContainer.querySelectorAll("button");
        for (const btn of buttons) {
            if (btn === htmlNodeBtn) {
                btn.style.backgroundColor = color;
                continue;
            }

            btn.style.backgroundColor = "#fff";
        }
    }
}
