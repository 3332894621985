async function getFolder(folderId) {
    let response;
    try {
        response = await axios.get(`/api/v1/folders/${folderId}`);
    } catch (e) {
        console.error(e);
        return false;
    }

    return await response.data;
}

async function addToFolder(folderId, listId) {
    let response;
    try {
        response = await axios.post(
            `/api/v1/folders/${folderId}/lists/${listId}`
        );
    } catch (e) {
        console.error(e);
        return false;
    }

    return await response.data;
}
async function removeFromFolder(folderId, listId) {
    let response;
    try {
        response = await axios.delete(
            `/api/v1/folders/${folderId}/lists/${listId}`
        );
    } catch (e) {
        console.error(e);
        return false;
    }

    return await response.data;
}

async function createFolder(name) {
    let response;
    const resData = { data: null, error: null };
    try {
        response = await axios.post("/api/v1/folders", {
            name: name,
        });
    } catch (e) {
        resData.error = e.response?.data?.error ?? e;
        return resData;
    }

    resData.data = await response.data.data;
    return resData;
}

async function deleteFolder(folderId) {
    let response;
    try {
        response = await axios.delete(`/api/v1/folders/${folderId}`);
    } catch (e) {
        console.error(e);
        return false;
    }

    return await response.data;
}

async function renameItem(folderId, name) {
    let response;
    const resData = { data: null, error: null };
    try {
        response = await axios.patch(`/api/v1/folders/${folderId}`, {
            name: name,
        });
    } catch (e) {
        resData.error = e.response?.data?.error ?? e;
        return resData;
    }

    resData.data = await response.data.data;
    return resData;
}

async function sortFolder(folderId, listIds) {
    let response;
    try {
        response = await axios.put(`/api/v1/folders/${folderId}`, {
            lists: listIds,
        });
    } catch (e) {
        console.error(e);
        return false;
    }

    return await response.data;
}

export default {
    getFolder,
    addToFolder,
    removeFromFolder,
    createFolder,
    deleteFolder,
    renameItem,
    sortFolder,
};
