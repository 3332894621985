export class GridBox {
    constructor(id) {
        this.id = id;

        const componentContainer = document.getElementById(id);

        this.cardsContainer = componentContainer.querySelector(
            "div[role='grid-cards']"
        );
        if (!this.cardsContainer) {
            throw new Error("Component container not found");
        }

        this.loader = componentContainer.querySelector("div[role='loader']");
        if (!this.loader) {
            throw new Error("Loader not found");
        }

        // Save instace in the Alpine store so we can retrive it later from the filter component
        window.Alpine.store(id, this);

        /* document.addEventListener("alpine:initialized", () => {
            this.fixTitlesLabels();
        }); */
    }

    wipeGridBox() {
        this.cardsContainer.innerHTML = "";
    }

    updateGridBox(items) {
        this.cardsContainer.innerHTML = "";

        for (const item of items) {
            const itemNode = window.Utils.createNodeFromString(item);
            this.cardsContainer.appendChild(itemNode);
        }

        window.AOS.refreshHard();
    }

    showLoader() {
        if (!this.loader.classList.contains("grid-loader-visible")) {
            this.loader.classList.add("grid-loader-visible");
        }
    }

    hideLoader() {
        if (this.loader.classList.contains("grid-loader-visible")) {
            this.loader.classList.remove("grid-loader-visible");
        }
    }

    /* fixTitlesLabels() {
        const container = document.getElementById(this.id);
        const titles = container.querySelectorAll([".item-name-2>.badge-like"]);

        for (let title of titles) {
            lining(title, {
                autoResize: true,
                lineClass: "line",
            });

            title.addEventListener(
                "afterlining",
                function () {
                    this.classList.toggle("clamp-me", this.children.length > 2);
                    this.className = this.className.replace(
                        /\bhas-lines-\d+?/g,
                        ""
                    );
                    this.classList.add("has-lines-" + this.children.length);
                    // do something after lining
                },
                false
            );
        }
    } */
}
